import React from "react";
import NavigationBar from "./NavigationBar";
import Footer from "./Footer";

const contact = () => {
  return (
    <>
      <NavigationBar />
      <section className="min-h-screen flex items-center justify-center px-6">
        <div className="container mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Left Side - Contact Info */}
          <div className="flex flex-col justify-center">
            <h1 className="text-6xl font-bold text-black mb-4">Contact Us</h1>
            <p className="text-gray-700 leading-relaxed">
              Need to get in touch with us? Either fill out the form with your
              enquiry, or reach out to us directly at our support email. Our
              team is here to help you with any questions, concerns, or feedback
              you may have!
            </p>
            <p className="text-gray-700 mt-4">
              <strong>Email:</strong> support@myprintcorner.com
            </p>
            <p className="text-gray-700 mt-2">
              <strong>Phone:</strong> +1-234-567-890
            </p>
          </div>

          {/* Right Side - Contact Form */}
          <div>
            <form className="bg-gray-100 p-6 rounded-lg shadow-md">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <div>
                  <label
                    htmlFor="firstName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-teal-500 focus:border-teal-500"
                    placeholder="John"
                  />
                </div>
                <div>
                  <label
                    htmlFor="lastName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-teal-500 focus:border-teal-500"
                    placeholder="Doe"
                  />
                </div>
              </div>

              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-teal-500 focus:border-teal-500"
                  placeholder="you@example.com"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="message"
                  className="block text-sm font-medium text-gray-700"
                >
                  What can we help you with?
                </label>
                <textarea
                  id="message"
                  rows="3"
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md "
                  placeholder="Enter your message here..."
                ></textarea>
              </div>

              <button
                type="submit"
                className="w-full bg-blue-500 text-white py-2 px-4 rounded-md focus:outline-none hover:bg-blue-600 focus:ring-2 focus:ring-offset-2"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
export default contact;
