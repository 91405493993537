import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import NavigationBar from "./NavigationBar";
import Footer from "./Footer";
//import logger from "../utils/logger";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");
    const navigate = useNavigate();
    const location = useLocation();
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

    // Get the previous page to redirect to after login
    const from = location.state?.from?.pathname || "/";// Default to Home if no "from" is set
    const printerShop = JSON.parse(sessionStorage.getItem("printerShop"));
    const fileDetails = JSON.parse(sessionStorage.getItem("fileDetails"));
    const files = JSON.parse(sessionStorage.getItem("files"));

    const handleSignupRedirect = () => {
        navigate("/signup"); 
    };

    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email, password }),
            });

            const data = await response.json();

            if (response.ok) {
                sessionStorage.setItem("token", data.token);
                sessionStorage.setItem("userEmail", email);

                navigate(from, { state: { email, printerShop, fileDetails,files} });
            } else {
                setMessage(data.message || "Failed to login");
            }
        } catch (error) {
            console.error("Login error:", error);
            setMessage("An error occurred. Please try again.");
        }
    };

    const handleGoogleLogin = async (credentialResponse) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/auth/google`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ token: credentialResponse.credential }),
            });

            const data = await response.json();

            if (response.ok) {
                sessionStorage.setItem("token", data.token);
                sessionStorage.setItem("userEmail", data.user.email);

                // Redirect to the previous page or Home
                navigate(from, { state: { email: data.user.email, printerShop, fileDetails ,files} });
            } else {
                setMessage(data.message || "Google login failed");
            }
        } catch (error) {
            console.error("Google login error:", error);
            setMessage("An error occurred. Please try again.");
        }
    };

    return (
        <GoogleOAuthProvider clientId={clientId}>
            <div>
                <NavigationBar />
                <div className="flex flex-col items-center justify-center h-[92vh] mx-4">
                    <div className="w-full md:w-96 p-4 border border-gray-300 rounded-md">
                        <h2 className="text-2xl md:text-3xl font-bold text-center">
                            Login to Printer Service
                        </h2>
                        <p className="text-base text-center text-gray-600 mb-4">
                            Please enter your email and password to login.
                        </p>
                        <form onSubmit={handleLogin}>
                            <label className="text-gray-900 font-semibold">
                                Email Address:
                                <input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    className="w-full p-2 border border-gray-300 rounded-md mb-2 font-medium text-gray-700"
                                />
                            </label>
                            <br />
                            <label className="text-gray-900 font-semibold">
                                Password:
                                <input
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                    className="w-full p-2 border border-gray-300 rounded-md mb-2 font-medium text-gray-700"
                                />
                            </label>
                            <br />
                            <button
                                className="bg-blue-500 text-white px-4 py-2 rounded-md w-full hover:bg-blue-600 transition-colors duration-200 ease-in-out mt-2 font-medium"
                                type="submit"
                            >
                                Login
                            </button>
                        </form>
                      
                        <div className="mt-4">
                            <GoogleLogin
                                onSuccess={handleGoogleLogin}
                                onError={() => setMessage("Google login failed")}
                            />
                        </div>
                        {message && <p className="mt-4 text-center text-red-600">{message}</p>}

                        <div className="mt-4">
                            <p className="text-base text-center text-black mb-4">
                                If not registered.
                            </p>
                            <div className="flex justify-center">
                                <button
                                    onClick={handleSignupRedirect}
                                    className="bg-blue-500 text-white px-6 py-2 rounded-lg shadow-md hover:bg-blue-600 transition duration-200"
                                >
                                    Sign Up
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </GoogleOAuthProvider>
    );
};

export default Login;
