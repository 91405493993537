import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NavigationBar from "./NavigationBar";
import { MdKeyboardArrowRight } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import axios from 'axios'

//const logger = require('../utils/logger')
export const Success = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    //const [showPopup, setShowPopup] = useState(false);
    const [generatedCode, setGeneratedCode] = useState(null);
    

    const { printerShop, fileDetails, email, files } = location.state || {};
    useEffect(() => {
        // Check if the user is logged in
        const isUserLoggedIn = email && !email.startsWith("guest_");
        setIsLoggedIn(isUserLoggedIn);

        // Save state to sessionStorage for fallback
        if (printerShop) sessionStorage.setItem("printerShop", JSON.stringify(printerShop));
        if (fileDetails) sessionStorage.setItem("fileDetails", JSON.stringify(fileDetails));
        if (files) sessionStorage.setItem("files", JSON.stringify(files));

        // Retrieve any stored generated code
        const storedCode = sessionStorage.getItem("generatedCode");
        if (storedCode) {
            setGeneratedCode(storedCode);
        }
        else {
            handleGenerateCode()
        }
    }, [email, printerShop, fileDetails, files]);


    const handleBack = () => {
        navigate("/select-printer-shop", {
            state: {
                fileOptions: fileDetails.map((file) => ({
                    filename: file.fileName,
                    colorOption: file.colorOption,
                    printType: file.printType,
                    paperSize: file.paperSize,
                    pagesPerSheet: file.pagesPerSheet,
                    margins: file.margins,
                    layout: file.layout,
                    totalRate: parseFloat(file.totalRate),
                })),
                email,
                files,
            },
        });
    };

    const handleGenerateCode = () => {
        const code = Math.floor(1000 + Math.random() * 9000); // Generate 4-digit code
            setGeneratedCode(code);
            sessionStorage.setItem("generatedCode", code);

            const newHistoryEntry = {
                printerShop: printerShop.name,
                totalCost: fileDetails.reduce((sum, file) => sum + parseFloat(file.totalRate), 0),
                files: fileDetails.map(file => file.fileName),
                date: new Date().toLocaleString(),
            };

            const userHistory = JSON.parse(localStorage.getItem(email)) || [];
            userHistory.push(newHistoryEntry);
            localStorage.setItem(email, JSON.stringify(userHistory));
    };

    const handleEdit = (fileIndex) => {
        navigate("/review-print", {
            state: {
                fileIndex,
                fileOptions: fileDetails.map((file) => ({
                    name: file.fileName,
                    colorOption: file.colorOption,
                    printType: file.printType,
                    paperSize: file.paperSize,
                    pagesPerSheet: file.pagesPerSheet,
                    margins: file.margins,
                    layout: file.layout,
                    totalRate: file.totalRate,
                })),
                email,
                files,
            },
        });
    };


    const handleSubmit = async () => {
        files.map((file, i) => {
            Object.assign(file, fileDetails[i])
        })
        const formData = new FormData();
        const totalAmount = fileDetails.reduce(
            (total, file) => total + parseFloat(file.totalRate || 0),
            0
        )
        formData.append("files", JSON.stringify(files));
        formData.append("email", email);
        formData.append("printerShop", printerShop._id);
        formData.append("code", generatedCode);
        formData.append("totalAmount", totalAmount);

        const apiUrl = process.env.REACT_APP_API_URL;
        const token = sessionStorage.getItem("token")
        try {
            await axios.post(`${apiUrl}/jobs`, formData, {
                headers: {
                    "Content-Type": "application/json",
                    ...(token && { Authorization: `Bearer ${token}` }),
                },
            });
            ['fileDetails', 'files', 'printerShop', 'generatedCode'].map(key => sessionStorage.removeItem(key))
            navigate('/history'); //guest user not implemented
        } catch (error) {
            alert('An error occurred. Please try again.');
            console.error(error);
        }
    }

    if (!printerShop || !fileDetails) {
        return (
            <div>
                <p>Invalid details provided.</p>
                <button onClick={() => navigate("/")}>Go Back</button>
            </div>
        );
    }

    return (
        <>
            <NavigationBar />
            <div
                class="
        flex items-center justify-center
        md:space-x-4 mt-6 mx-6
        space-x-2
      "
            >
                <div class="flex flex-col items-center">
                    <div class="w-4 h-4 rounded-full bg-[#5360FD]"></div>
                    <span class="mt-2 text-black font-medium text-xs md:text-base text-center">
                        Upload File
                    </span>
                </div>
                <div class="flex-1 h-0.5 bg-[#5360FD]"></div>
                <div class="flex flex-col items-center">
                    <div class="w-4 h-4 rounded-full bg-[#5360FD]"></div>
                    <span class="mt-2 text-black font-medium text-xs md:text-base text-center">
                        Upload File
                    </span>
                </div>
                <div class="flex-1 h-0.5 bg-[#5360FD]"></div>
                <div class="flex flex-col items-center">
                    <div class="w-4 h-4 rounded-full bg-[#5360FD]"></div>
                    <span class="mt-2 text-black font-medium text-xs md:text-base text-center">
                        Print Location
                    </span>
                </div>
                <div class="flex-1 h-0.5 bg-[#5360FD]"></div>
                <div class="flex flex-col items-center">
                    <div class="w-4 h-4 border-4 border-[#5360FD] rounded-full bg-white"></div>
                    <span class="mt-2 text-black font-medium text-xs md:text-base text-center">
                        Order Summary
                    </span>
                </div>
            </div>
            <div
                className="p-8">
                <div className="success-container flex flex-col space-y-2">
                    <div className="flex flex-col space-y-2">
                        <span className="text-3xl font-bold">
                            Just one last step...
                        </span>
                        <span className="text-gray-500 italic">Please confirm if everything right and edit your order if you want to.</span>
                    </div>
                    <div className="flex justify-between items-center h-10 p-2 bg-gray-200 rounded-lg">
                        <span>Printer Shop: <strong>{printerShop.name}</strong></span>
                        <span>{isLoggedIn ? "Email: ": "Guest ID: "}<strong>{email}</strong></span>
                    </div>

                    <table className="table-auto border-collapse w-full text-left">
                        <thead>
                            <tr>
                                <th className="px-4 py-2 bg-[#5360FD] text-white font-medium">
                                    S.No.
                                </th>
                                <th className="px-4 py-2 bg-[#5360FD] text-white font-medium">
                                    File Name
                                </th>
                                <th className="px-4 py-2 bg-[#5360FD] text-white font-medium">
                                    Color Option
                                </th>
                                <th className="px-4 py-2 bg-[#5360FD] text-white font-medium">
                                    Pages
                                </th>
                                <th className="px-4 py-2 bg-[#5360FD] text-white font-medium">
                                    Total Rate (₹)
                                </th>
                                <th className="px-4 py-2 bg-[#5360FD] text-white font-medium">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {fileDetails.length > 0
                                ? fileDetails.map((file, index) => (
                                    <tr
                                        key={index}
                                        className="hover:bg-gray-100 font-semibold border-r-[1px] border-l-[1px] border-black border-b-[1px]"
                                    >
                                        <td className="px-4 py-2 text-center">{index + 1}</td>
                                        <td className="px-4 py-2">
                                            {files && files[index]?.originalName
                                                ? files[index].originalName
                                                : file.filename}
                                            <br />
                                            <span
                                                onClick={() => {
                                                    const updatedFiles = fileDetails.filter(
                                                        (_, i) => i !== index
                                                    );
                                                    navigate("/success", {
                                                        state: {
                                                            printerShop,
                                                            fileDetails: updatedFiles,
                                                            email,
                                                        },
                                                    });
                                                }}
                                                className="text-base text-red-500 hover:underline cursor-pointer font-medium"
                                            >
                                                Remove
                                            </span>
                                        </td>
                                        <td className="px-4 py-2">{file.colorOption}</td>
                                        <td className="px-4 py-2">{file.pagesPerSheet}</td>
                                        <td className="px-4 py-2">{file.totalRate}</td>
                                        <td className="px-4 py-2">
                                            <button
                                                onClick={() => handleEdit(index)}
                                                className="bg-green-600 text-white px-3 py-1 rounded hover:bg-green-700 transition duration-300 ease-in-out flex items-center justify-center"
                                            >
                                                <FaEdit className="mr-1" />
                                                Edit
                                            </button>
                                        </td>
                                    </tr>
                                ))
                                : // Add empty rows as placeholders
                                Array.from({ length: 5 }).map((_, index) => (
                                    <tr
                                        key={`placeholder-${index}`}
                                        className="bg-gray-50 border-r-[1px] border-l-[1px] border-black border-b-[1px]"
                                    >
                                        <td
                                            className="px-4 py-2 text-center text-gray-400 italic"
                                            colSpan={6}
                                        >
                                            {index === 0 ? "No files uploaded yet" : ""}
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                        <tfoot>
                            <tr className="border-r-[1px] border-black border-l-[1px] border-b-[1px] hover:bg-gray-100 font-semibold">
                                <td className="px-4 py-2 text-left" colSpan={3}>
                                    Total Number of Pages:{" "}
                                    {fileDetails.reduce(
                                        (total, file) => total + parseFloat(file.pagesPerSheet || 0),
                                        0
                                    )}
                                </td>
                                <td className="px-4 py-2 text-left" colSpan={3}>
                                    Total Amount: ₹
                                    {fileDetails.reduce(
                                        (total, file) => total + parseFloat(file.totalRate || 0),
                                        0
                                    )}
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <div
                    className="flex justify-between mt-8 w-full">
                    <button
                        onClick={handleBack}
                        className="bg-black text-white px-6 py-3 rounded-full items-center flex justify-center hover:bg-[#5360FD] transition-colors duration-100 ease-linear"
                    >
                        <MdKeyboardArrowRight className="text-2xl mr-2 rotate-180" />
                        Back
                    </button>
                    <div className="flex justify-center mt-6 text-2xl">
                        {generatedCode && <span>Your Generated Code: <span className="font-bold text-red-600 tracking-wider">{generatedCode}</span></span>}
                    </div>
                    <button className="bg-green-600 text-white px-6 py-2 rounded-lg shadow-md hover:bg-green-700 transition duration-200" onClick={handleSubmit} >
                        Done
                    </button>
                </div>
            </div>
        </>
    );
};

export default Success;