import React from "react";
import NavigationBar from "./NavigationBar";
import Footer from "./Footer";

const privacy = () => {
  return (
    <>
      <NavigationBar />
      <section className="min-h-screen flex items-center justify-center px-4 mt-8 md:mt-0">
        <div>
          <h1 className="text-4xl font-bold text-black mb-6">
            Privacy Policy & Terms and Conditions
          </h1>
          <p className="text-gray-600 text-sm mb-4">
            Effective Date: 23-12-2024
          </p>
          <p className="text-gray-700 text-left leading-relaxed mb-4">
            At My Print Corner, we are committed to providing a secure and
            seamless printing experience while safeguarding your data. By using
            our platform, you agree to the following policies and terms.
          </p>
          <div className="text-left text-gray-700 leading-relaxed">
            <h2 className="font-semibold mb-2">1. Data Collection and Usage</h2>
            <p>
              We collect and use data only to process your orders efficiently
              and securely:
              <ul className="list-disc list-inside ml-4 mt-2">
                <li>
                  Personal Information: Name, email, contact details, and
                  payment information.
                </li>
                <li>
                  File Uploads: Files are securely stored, encrypted, and
                  processed exclusively for printing.
                </li>
                <li>
                  Cookies: Non-essential cookies are used to improve website
                  functionality and user experience.
                </li>
              </ul>
            </p>

            <h2 className="font-semibold mt-6 mb-2">2. File Handling</h2>
            <p>
              Your uploaded files are never accessible to the printer shop.
              Files are processed securely and protected from unauthorized
              access. Files are automatically deleted:
              <ul className="list-disc list-inside ml-4 mt-2">
                <li>
                  From the printer's system immediately after the job is
                  processed.
                </li>
                <li>From our servers 7 days after order completion.</li>
                <li>
                  Users can request immediate file deletion by contacting
                  support.
                </li>
              </ul>
            </p>

            <h2 className="font-semibold mt-6 mb-2">
              3. User Responsibilities
            </h2>
            <p>
              By using My Print Corner, you agree:
              <ul className="list-disc list-inside ml-4 mt-2">
                <li>
                  To upload only files you own or have the legal right to use.
                </li>
                <li>
                  To ensure the uploaded content complies with applicable laws
                  and does not include prohibited, offensive, or copyrighted
                  material.
                </li>
              </ul>
            </p>

            <h2 className="font-semibold mt-6 mb-2">
              4. Security and Confidentiality
            </h2>
            <p>
              We use industry-standard encryption and secure storage systems to
              protect your data and files. Your personal information and files
              will not be shared with third parties except for processing your
              orders through trusted service providers (e.g., payment gateways).
            </p>

            <h2 className="font-semibold mt-6 mb-2">
              5. Limitation of Liability
            </h2>
            <p>
              My Print Corner is not liable for the content of uploaded files or
              any misuse of files by users. We are not responsible for indirect
              losses or delays caused by technical issues or user errors.
            </p>

            <p className="mt-6">
              By using My Print Corner, you acknowledge and accept these terms
              and conditions. For any inquiries, contact us at{" "}
              <a href="#" className="text-teal-600 underline">
                Contact Us
              </a>
              .
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
export default privacy;
