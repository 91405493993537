import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import NavigationBar from "./NavigationBar";
import { RxCross2 } from "react-icons/rx";
import { CiCirclePlus } from "react-icons/ci";
import { FaPlus } from "react-icons/fa6";
import { MdKeyboardArrowRight } from "react-icons/md";
import { FaCircleArrowLeft, FaCircleArrowRight } from "react-icons/fa6";
import Footer from "./Footer";
import { FaMinus } from "react-icons/fa";
//const logger = require('../utils/logger')
const ReviewAndPrint = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const { files = [], options = {}, email = "" } = location.state || {};
    const [existingFiles, setExistingFiles] = useState(files);
    const [applyToAll, setApplyToAll] = useState(false);
    const [fileOptions, setFileOptions] = useState(
        files.map(() => ({
            paperSize: options.paperSize || "A4",
            printType: options.printType || "Glossy",
            colorOption: options.colorOption || "Colored",
            pagesPerSheet: options.pagesPerSheet || "1",
            margins: options.margins || "Normal",
            layout: options.layout || "Portrait",
            copies: options.copies || 1,
        }))
    );
    const [currentFileIndex, setCurrentFileIndex] = useState(0);
    const [expanded, setExpanded] = useState(false);
    const [printCount, setPrintCount] = useState(1);

    const toggleExpanded = () => setExpanded((prev) => !prev);

    const handleDecrement = () => {
        setPrintCount((prevCount) => Math.max(1, prevCount - 1));
    };

    const handleIncrement = () => {
        setPrintCount((prevCount) => prevCount + 1);
    };

    const handleInputChange = (e) => {
        const value = Math.max(1, parseInt(e.target.value) || 1);
        setPrintCount(value);
    };

    useEffect(() => {
        if (!files.length) {
            alert("No files to review.");
            navigate("/");
        }
    }, [files, navigate]);

    // useEffect(() => {
    //     const fetchFileOptions = async () => {
    //         try {
    //             const apiUrl = process.env.REACT_APP_API_URL;
    //             const response = await axios.get(`${apiUrl}/reviewFiles`, {
    //                 params: { email },
    //             });

    //             const { files = [], fileOptions = [] } = response.data;

    //             setExistingFiles(files);
    //             setFileOptions(fileOptions);
    //         } catch (error) {
    //             console.error("Error fetching file options:", error);
    //             //alert("Failed to fetch file options. Please try again.");
    //         }
    //     };

    //     fetchFileOptions();
    // }, [email]);

    const handleOptionChange = (optionType, value) => {
        setFileOptions((prevOptions) => {
            const updatedOptions = [...prevOptions];
            updatedOptions[currentFileIndex] = {
                ...updatedOptions[currentFileIndex],
                [optionType]: value,
            };
            return updatedOptions;
        });
    };

    const handleFileUpload = async (e) => {
        const selectedFiles = Array.from(e.target.files);
        if (selectedFiles.length === 0) return;

        const formData = new FormData();
        selectedFiles.forEach((file) => {
            formData.append("files", file);
        });

        const emailToUse = email || `guest_${Date.now()}`;
        formData.append("emailToUse", emailToUse);

        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = sessionStorage.getItem("token")
            const response = await axios.post(`${apiUrl}/upload`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    ...(token && { Authorization: `Bearer ${token}` })
                }
            });

            let uploadedFiles = response.data.files;

            setExistingFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);
        } catch (error) {
            console.error("Error uploading files:", error);
            alert("Error uploading files. Please try again later.");
        }
    };

    const handleNext = () => {
        if (currentFileIndex < existingFiles.length - 1) {
            setCurrentFileIndex(currentFileIndex + 1);
        } else {
            // alert("You've reviewed all files.");
        }
    };

    const handleApplyToAll = () => {
        const updatedOptions = fileOptions[currentFileIndex];
        setFileOptions((prevOptions) =>
            prevOptions.map(() => ({ ...updatedOptions }))
        );
        setApplyToAll(true);
        // alert("Options applied to all files.");
    };

    const handleDeleteFile = (index) => {
        const updatedFiles = existingFiles.filter(
            (_, fileIndex) => fileIndex !== index
        );
        const updatedOptions = fileOptions.filter(
            (_, optionIndex) => optionIndex !== index
        );

        setExistingFiles(updatedFiles);
        setFileOptions(updatedOptions);

        setCurrentFileIndex((prevIndex) =>
            prevIndex >= updatedFiles.length ? Math.max(0, updatedFiles.length - 1) : prevIndex
        );

        if (updatedFiles.length === 0) {
            alert("No files left. Please upload new files.");
        }
    };

    const handleProceedToNext = async () => {
        try {
            navigate("/select-printer-shop", {
                state: { email,selectedShop, fileOptions, files: existingFiles, printCount },
            });
        } catch (error) {
            console.error("Error saving changes:", error);
            alert("Failed to save changes. Please try again.");
        }
    };

    const handleBlackAndWhite = () => {
        const previewImage = document.getElementById("filePreviewImage");
        const coloredBTN = document.getElementById("coloredBTN");
        previewImage.classList.remove("grayscale");
    };

    const updatePreviewPadding = (margin) => {
        const previewElement = document.getElementById("filePreviewImage");
        if (!previewElement) return;

        switch (margin) {
            case "Normal":
                previewElement.classList.add("p-4");
                previewElement.classList.remove("p-2", "p-6");
                break;
            case "Narrow":
                previewElement.classList.add("p-2");
                previewElement.classList.remove("p-4", "p-6");
                break;
            case "Wide":
                previewElement.classList.add("p-6");
                previewElement.classList.remove("p-2", "p-4");
                break;
        }
    };


    
    const { selectedShop } = location.state || {}; // Extract shopId and selectedShop
    




    
    
    


    const handleProceedToSelectShop = () => {
        navigate("/select-printer-shop", {
            state: {
                selectedShop,
                files: existingFiles,
                fileOptions,
                email,
            },
        });
    };










    return (
        <div>
            <NavigationBar />
            {/* PC INTERFACE */}
            <div className="hidden md:block">
                <main
                    id="mainTabletAbove"
                    className="
        md:flex flex-col lg:flex-row justify-center items-center
        w-full
      "
                >
                    <div
                        id="fileCarousel"
                        className="
            w-full lg:w-1/2 lg:mx-8
            flex flex-col
            justify-center
        "
                    >
                        {/* Tracker Start */}

                        <div id="Tracker" className="w-full">
                            <div class="flex items-center justify-center mt-16 space-x-8 text-sm w-full">
                                <div class="flex flex-col items-center">
                                    <div class="w-4 h-4 rounded-full bg-[#5360FD]"></div>
                                    <span class="mt-2 text-black font-medium">Upload File</span>
                                </div>
                                <div class="flex-1 h-0.5 bg-[#5360FD]"></div>
                                <div class="flex flex-col items-center">
                                    <div class="w-4 h-4 border-4 border-[#5360FD] rounded-full bg-white"></div>
                                    <span class="mt-2 text-black font-medium">
                                        Print Settings
                                    </span>
                                </div>
                                <div class="flex-1 h-0.5 bg-[#5360FD]"></div>
                                <div class="flex flex-col items-center">
                                    <div class="w-4 h-4 rounded-full bg-[#5360FD]"></div>
                                    <span class="mt-2 text-black font-medium">
                                        Select Location
                                    </span>
                                </div>
                                <div class="flex-1 h-0.5 bg-[#5360FD]"></div>
                                <div class="flex flex-col items-center">
                                    <div class="w-4 h-4 rounded-full bg-[#5360FD]"></div>
                                    <span class="mt-2 text-black font-medium">Order Summary</span>
                                </div>
                            </div>
                        </div>
                        {/* Tracker End */}
                        {/* Carousel Start */}
                        <div className="flex justify-center w-full flex-col mx-2 scale-90">
                            <div className="flex items-center justify-between w-full h-[60vh] p-6 bg-white border-[1px] border-gray-300 rounded-2xl shadow-lg hover:shadow-xl transition duration-300 ease-in-out">
                                {/* Left Arrow Button */}
                                <button
                                    onClick={() =>
                                        setCurrentFileIndex((prev) => Math.max(0, prev - 1))
                                    }
                                    className={`text-4xl text-[#5360FD] hover:text-black transition-colors duration-100 ease-linear mr-4 cursor-pointer ${currentFileIndex === 0
                                        ? "opacity-50 cursor-not-allowed"
                                        : ""
                                        }`}
                                    disabled={currentFileIndex === 0}
                                >
                                    <FaCircleArrowLeft />
                                </button>

                                {/* File Carousel */}
                                <div className="flex items-start justify-between w-full gap-4">
                                    {existingFiles
                                        .slice(currentFileIndex, currentFileIndex + 2)
                                        .map((file, index) => (
                                            <div
                                                key={index}
                                                className="w-1/3 flex flex-col justify-center items-center gap-y-2"
                                            >
                                                <div className="relative w-full h-full">
                                                    <img
                                                        src={existingFiles[currentFileIndex]?.thumbnailUrl}
                                                        alt="file"
                                                        className={`w-full h-96 border-2 rounded-lg ${index + currentFileIndex === currentFileIndex
                                                            ? "border-[#5360FD]"
                                                            : "border-gray-400"
                                                            } hover:border-[#5360FD] hover:shadow-sm transition-colors duration-100 ease-linear p-4`}
                                                    />
                                                    <button
                                                        id={index}
                                                        onClick={() => handleDeleteFile(index)}
                                                        className="absolute top-2 right-2 text-white bg-red-500 rounded-full px-1 py-1 hover:bg-red-600 transition-colors duration-100 ease-linear"
                                                    >
                                                        <RxCross2 className="text-xl" />
                                                    </button>
                                                </div>
                                                <h1 className="text-sm font-medium truncate w-full text-center">
                                                    File Name: {file.originalName}
                                                </h1>
                                            </div>
                                        ))}

                                    {/* Add More Files Button */}
                                    <label
                                        htmlFor="dropzone-file"
                                        className="w-1/3 cursor-pointer flex flex-col items-center justify-center h-96 border-2 border-gray-300 rounded-lg text-center px-8 py-4 hover:bg-gray-50 transition-colors duration-200 ease-linear"
                                    >
                                        <div className="flex flex-col items-center justify-center pt-5 pb-6 text-[#5360FD] font-semibold">
                                            <CiCirclePlus className="text-4xl" />
                                            <p>Add More Files</p>
                                        </div>
                                        <input
                                            max={10}
                                            id="dropzone-file"
                                            type="file"
                                            className="hidden"
                                            accept="image/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation"
                                            multiple
                                            onChange={handleFileUpload}
                                        />
                                    </label>
                                </div>

                                {/* Right Arrow Button */}
                                <button
                                    onClick={() =>
                                        setCurrentFileIndex((prev) =>
                                            Math.min(prev + 1, existingFiles.length - 1)
                                        )
                                    }
                                    className={`text-4xl text-[#5360FD] hover:text-black transition-colors duration-100 ease-linear ml-4 cursor-pointer ${currentFileIndex === existingFiles.length - 1
                                        ? "opacity-50 cursor-not-allowed"
                                        : ""
                                        }`}
                                    disabled={currentFileIndex === existingFiles.length - 1}
                                >
                                    <FaCircleArrowRight />
                                </button>
                            </div>
                        </div>
                        {/* Carousel End */}
                        {/* Proceed Button */}
                        <div className="w-full flex items-end justify-end scale-90">
                            <button className="bg-black text-white px-6 py-3 rounded-full items-center flex justify-center hover:bg-[#5360FD] transition-colors duration-100 ease-linear" onClick={handleProceedToSelectShop}>
                                Proceed to Select Shop
                                <MdKeyboardArrowRight className="text-2xl ml-2" />
                            </button>
                        </div>
                        {/* Proceed Button End */}
                    </div>
                    <div
                        id="filePreview"
                        className="w-full lg:w-1/2 mx-8 flex items-center justify-center flex-col"
                    >
                        <div
                            className="
            border-2 p-4 rounded-lg shadow-lg w-1/2 flex justify-center flex-col items-center scale-75
            translate-y-8
          "
                        >
                            <h1
                                className="
                text-center
                text-lg font-medium
            "
                            >
                                Print Options for Selected File
                            </h1>
                            <img
                                src={existingFiles[currentFileIndex]?.thumbnailUrl}
                                alt="file"
                                className={`
                w-64 h-96 border-2 rounded-lg p-2
                border-gray-300
                mt-4 ${fileOptions[currentFileIndex].colorOption === "black and white" ? "grayscale" : "grayscale-0"}
              `}
                                id="filePreviewImage"
                            />
                        </div>
                        <div
                            className="
            border w-1/2 mt-4 p-3 rounded-lg shadow-lg
            flex flex-row justify-between items-center
          "
                        >
                            <span
                                className="
                text-lg font-medium
            "
                            >
                                Pages
                            </span>
                            <select
                                className="
                border-2 rounded-lg p-1
                border-gray-300
            "
                            >
                                <option value="ALL">All</option>
                                <option value="ODD">Odd</option>
                                <option value="EVEN">Even</option>
                            </select>
                        </div>
                        <div
                            className="
        border w-1/2 mt-4 p-3 rounded-lg shadow-lg 
        flex flex-row justify-between items-center
    "
                        >
                            <span className="text-lg font-medium">
                                Colour
                            </span>
                            <select
                                className="
            border-2 rounded-lg p-1 
            border-gray-300 focus:outline-none 
            focus:ring-2 focus:ring-blue-500 focus:border-blue-500
        "
                                onChange={(e) => handleOptionChange("colorOption", e.target.value)}
                            >
                                <option value="color">Color</option>
                                <option value="black and white">B&W</option>
                            </select>
                        </div>

                        <div
                            className="
            border w-1/2 mt-4 p-3 rounded-lg shadow-lg
            flex flex-row justify-between items-center
          "
                        >
                            <span
                                className="
                text-lg font-medium
            "
                            >
                                Number of Copies
                            </span>
                            <div
                                className="
                flex flex-row justify-between items-center gap-x-1
            "
                            >
                                <button
                                    onClick={handleDecrement}
                                    className="
                        bg-[#5360FD] text-white px-2 py-1 rounded-lg h-8 w-8
                        hover:bg-black transition-colors duration-100 ease-linear
                        "
                                >
                                    <FaMinus />
                                </button>
                                <input
                                    type="number"
                                    value={printCount}
                                    onChange={handleInputChange}
                                    className="
                        border-2 rounded-lg p-1 w-12 text-center
                        border-gray-300 h-8
                        "
                                />
                                <button
                                    onClick={handleIncrement}
                                    className="
                        bg-[#5360FD] text-white px-2 py-1 rounded-lg
                        hover:bg-black transition-colors duration-100 ease-linear
                        h-8 w-8
                        "
                                >
                                    <FaPlus />
                                </button>
                            </div>
                        </div>
                        <div>
                            <button
                                onClick={toggleExpanded}
                                className="
                text-[#5360FD] font-medium
                hover:text-black transition-colors duration-100 ease-linear
                mt-4
                "
                            >
                                {expanded ? "Hide Settings" : "Show More Settings"}
                            </button>
                        </div>
                        {expanded && (
                            <>
                                <div
                                    className="
                border w-1/2 mt-4 p-3 rounded-lg shadow-lg
                flex flex-row justify-between items-center
              "
                                >
                                    <span
                                        className="
                    text-lg font-medium
                "
                                    >
                                        Paper Size
                                    </span>
                                    <select
                                        value={fileOptions[currentFileIndex].paperSize}
                                        onChange={(e) =>
                                            handleOptionChange("paperSize", e.target.value)
                                        }
                                        className="
                        border-2 rounded-lg p-1
                        border-gray-300
                    "
                                    >
                                        <option value="A4">A4</option>
                                        <option value="A3">A3</option>
                                        <option value="Letter">Letter</option>
                                    </select>
                                </div>
                                <div
                                    className="
                border w-1/2 mt-4 p-3 rounded-lg shadow-lg
                flex flex-row justify-between items-center
                "
                                >
                                    <span
                                        className="
                    text-lg font-medium
                    "
                                    >
                                        Paper Type
                                    </span>
                                    <select
                                        value={fileOptions[currentFileIndex].printType}
                                        onChange={(e) =>
                                            handleOptionChange("printType", e.target.value)
                                        }
                                        className="
                            border-2 rounded-lg p-1
                            border-gray-300
                        "
                                    >
                                        <option value="Glossy">Glossy</option>
                                        <option value="Matte">Matte</option>
                                    </select>
                                </div>
                                <div
                                    className="
                    border w-1/2 mt-4 p-3 rounded-lg shadow-lg
                    flex flex-row justify-between items-center
                    "
                                >
                                    <span
                                        className="
                        text-lg font-medium
                        "
                                    >
                                        Margins
                                    </span>
                                    <select
                                        value={fileOptions[currentFileIndex].margins}
                                        onChange={(e) => {
                                            const newMargin = e.target.value;
                                            handleOptionChange("margins", newMargin); // Update the margins in the state
                                            updatePreviewPadding(newMargin); // Update the preview image padding
                                        }}
                                        className="
                            border-2 rounded-lg p-1
                            border-gray-300
                        "
                                    >
                                        <option value="Normal">Normal</option>
                                        <option value="Narrow">Narrow</option>
                                        <option value="Wide">Wide</option>
                                    </select>
                                </div>
                            </>
                        )}
                        <div
                            className="
                flex flex-row items-center justify-center
                mt-4"
                        >
                            <button
                                onClick={handleApplyToAll}
                                className="
                        bg-black text-white px-6 py-2 rounded-lg
                        hover:bg-[#5360FD] transition-colors duration-100 ease-linear
                        "
                            >
                                Apply to All
                            </button>
                            <button
                                onClick={handleProceedToNext}
                                className="
                        bg-black text-white px-6 py-2 rounded-lg ml-4
                        hover:bg-[#5360FD] transition-colors duration-100 ease-linear
                        "
                            >
                                Save Changes
                            </button>
                        </div>
                    </div>
                </main>
            </div>
            {/* MOBILE INTERFACE */}
            <div
                className="
        block md:hidden
      "
            >
                <main>
                    {/* TRACKER START */}
                    <div id="Tracker" className="w-full">
                        <div class="flex items-center justify-center mt-16 space-x-4 text-sm w-full">
                            <div class="flex flex-col items-center">
                                <div class="w-4 h-4 rounded-full bg-[#5360FD]"></div>
                                <span class="mt-2 text-black font-medium text-center">
                                    Upload File
                                </span>
                            </div>
                            <div class="flex-1 h-0.5 bg-[#5360FD]"></div>
                            <div class="flex flex-col items-center">
                                <div class="w-4 h-4 border-4 border-[#5360FD] rounded-full bg-white"></div>
                                <span class="mt-2 text-black font-medium text-center">
                                    Print Settings
                                </span>
                            </div>
                            <div class="flex-1 h-0.5 bg-[#5360FD]"></div>
                            <div class="flex flex-col items-center">
                                <div class="w-4 h-4 rounded-full bg-[#5360FD]"></div>
                                <span class="mt-2 text-black font-medium text-center">
                                    Select Location
                                </span>
                            </div>
                            <div class="flex-1 h-0.5 bg-[#5360FD]"></div>
                            <div class="flex flex-col items-center">
                                <div class="w-4 h-4 rounded-full bg-[#5360FD]"></div>
                                <span class="mt-2 text-black font-medium text-center">
                                    Order Summary
                                </span>
                            </div>
                        </div>
                    </div>
                    {/* TRACKER END */}
                    <div
                        className="
            w-full flex flex-col items-center justify-center mt-4
          "
                    >
                        <div
                            id="Carousel"
                            className="
            w-96 border-2 border-gray-300 rounded-lg p-4 mt-4 shadow-lg
            flex flex-col items-center justify-center
          "
                        >
                            <h1
                                className="
                text-center
                text-lg font-medium
            "
                            >
                                Preview Files
                            </h1>
                            <div
                                className="
                flex flex-row items-center justify-center w-72
                gap-x-4 mt-4 rounded-lg p-2
            "
                            >
                                <div>
                                    <FaCircleArrowLeft
                                        className="
                    text-2xl text-[#5360FD] hover:text-black transition-colors duration-100 ease-linear cursor-pointer
                "
                                    />
                                </div>
                                <div>
                                    <img
                                        id="mobilePreview"
                                        src={existingFiles[currentFileIndex]?.thumbnailUrl}
                                    />
                                </div>
                                <div>
                                    <FaCircleArrowRight className="text-2xl text-[#5360FD] hover:text-black transition-colors duration-100 ease-linear cursor-pointer" />
                                </div>
                            </div>
                        </div>
                        <div className="mt-4 w-96">
                            <div
                                className="
                flex flex-row items-center gap-x-4 justify-between border shadow-sm rounded-lg p-2
              "
                            >
                                <span
                                    className="
                    text-lg font-medium
                "
                                >
                                    Number of Copies
                                </span>
                                <div
                                    className="
                  flex flex-row items-center justify-center gap-x-2
                  mt-2
                "
                                >
                                    <button
                                        onClick={handleDecrement}
                                        className="
                    bg-[#5360FD] text-white px-2 py-1 rounded-lg h-8 w-8
                    hover:bg-black transition-colors duration-100 ease-linear
                "
                                    >
                                        <FaMinus />
                                    </button>
                                    <input
                                        type="number"
                                        value={printCount}
                                        onChange={handleInputChange}
                                        className="
                        border-2 rounded-lg p-1 w-12 text-center
                        border-gray-300 h-8
                    "
                                    />
                                    <button
                                        onClick={handleIncrement}
                                        className="
                        bg-[#5360FD] text-white px-2 py-1 rounded-lg
                        hover:bg-black transition-colors duration-100 ease-linear
                        h-8 w-8
                    "
                                    >
                                        <FaPlus />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="mt-4 w-96">
                            <div
                                className="
            flex flex-row items-center gap-x-4 justify-between border shadow-sm rounded-lg p-2
        "
                            >
                                <span className="text-lg font-medium">
                                    Colour
                                </span>
                                <select
                                    className="
                border-2 rounded-lg p-1
                border-gray-300 focus:outline-none 
                focus:ring-2 focus:ring-blue-500 focus:border-blue-500
            "
                                    onChange={(e) => handleOptionChange("colorOption", e.target.value)}
                                >
                                    <option value="color">Color</option>
                                    <option value="black and white">B&W</option>
                                </select>
                            </div>
                        </div>

                        <div className="mt-4 w-96">
                            <div
                                className="
                        flex flex-row items-center gap-x-4 justify-between border shadow-sm rounded-lg p-2
                    "
                            >
                                <span
                                    className="
                            text-lg font-medium
                        "
                                >
                                    Paper Size
                                </span>
                                <select
                                    value={fileOptions[currentFileIndex].paperSize}
                                    onChange={(e) =>
                                        handleOptionChange("paperSize", e.target.value)
                                    }
                                    className="
                            border-2 rounded-lg p-1
                            border-gray-300
                        "
                                >
                                    <option value="A4">A4</option>
                                    <option value="A3">A3</option>
                                    <option value="Letter">Letter</option>
                                </select>
                            </div>
                        </div>
                        <div className="mt-4 w-96">
                            <div
                                className="
                            flex flex-row items-center gap-x-4 justify-between border shadow-sm rounded-lg p-2
                        "
                            >
                                <span
                                    className="
                                text-lg font-medium
                            "
                                >
                                    Paper Type
                                </span>
                                <select
                                    value={fileOptions[currentFileIndex].printType}
                                    onChange={(e) =>
                                        handleOptionChange("printType", e.target.value)
                                    }
                                    className="
                                border-2 rounded-lg p-1
                                border-gray-300
                            "
                                >
                                    <option value="Glossy">Glossy</option>
                                    <option value="Matte">Matte</option>
                                </select>
                            </div>
                        </div>
                        <div className="mt-4 w-96">
                            <div
                                className="
                                    flex flex-row items-center gap-x-4 justify-between border shadow-sm rounded-lg p-2
                                "
                            >
                                <span
                                    className="
                                        text-lg font-medium
                                    "
                                >
                                    Margins
                                </span>
                                <select
                                    value={fileOptions[currentFileIndex].margins}
                                    onChange={(e) => {
                                        const newMargin = e.target.value;
                                        handleOptionChange("margins", newMargin); // Update the margins in the state
                                        updatePreviewPadding(newMargin); // Update the preview image padding
                                    }}
                                    className="
                                        border-2 rounded-lg p-1
                                        border-gray-300
                                    "
                                >
                                    <option value="Normal">Normal</option>
                                    <option value="Narrow">Narrow</option>
                                    <option value="Wide">Wide</option>
                                </select>
                            </div>
                        </div>
                        <div
                            className="
                flex flex-row items-center justify-center
                mt-4
            "
                        >
                            <button
                                onClick={handleApplyToAll}
                                className="
                            bg-black text-white px-6 py-2 rounded-lg
                            hover:bg-[#5360FD] transition-colors duration-100 ease-linear
                            "
                            >
                                Apply to All
                            </button>
                            <button
                                onClick={handleProceedToNext}
                                className="
                            bg-black text-white px-6 py-2 rounded-lg ml-4
                            hover:bg-[#5360FD] transition-colors duration-100 ease-linear
                            "
                            >
                                Save Changes
                            </button>
                        </div>
                    </div>
                </main>
            </div>
            <Footer />
        </div>
    );
};

export default ReviewAndPrint;