import React, { useState } from "react";
import { GoogleLogin } from "@react-oauth/google";
import NavigationBar from "./NavigationBar";
import { useNavigate } from "react-router-dom";

const Signup = () => {
    const [userInfo, setUserInfo] = useState(null);
    const [message, setMessage] = useState("");
    const [formData, setFormData] = useState({
        email: "",
        password: "",
        confirmPassword: "",
    });


    const navigate = useNavigate();


    const handleGoogleSuccess = async (credentialResponse) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/auth/google`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ token: credentialResponse.credential }),
            });

            const data = await response.json();

            if (response.ok) {
                const { email, name } = data.user;
                setUserInfo({ email, name });
                setMessage("Welcome, " + name);
                setTimeout(() => navigate("/login"), 2000); // Redirect to login page
            } else {
                setMessage(data.message || "Google authentication failed");
            }
        } catch (error) {
            console.error("Google signup error:", error);
            setMessage("An error occurred. Please try again.");
        }
    };

    const handleGoogleFailure = () => {
        setMessage("Google Sign-In failed. Please try again later.");
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleManualSignup = async (e) => {
        e.preventDefault();

        const { email, password, confirmPassword } = formData;

        if (password !== confirmPassword) {
            setMessage("Passwords do not match.");
            return;
        }

        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/signup`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email, password }),
            });

            // Check if the response is JSON
            if (!response.ok) {
                const errorMessage = await response.text(); // Get the raw response text
                console.error("Non-JSON response:", errorMessage);
                setMessage("Signup failed: " + errorMessage);
                return;
            }

            const data = await response.json();

            if (response.ok) {
                setMessage("Signup successful! Redirecting to login...");
                setTimeout(() => navigate("/login"), 2000); // Redirect after 1 seconds
            } else {
                setMessage(data.message || "Signup failed.");
            }
        } catch (error) {
            console.error("Manual signup error:", error);
            setMessage("An error occurred. Please try again.");
        }
    };


    return (
        <div>
            <NavigationBar />
            <div className="flex flex-col items-center justify-center h-[92vh] mx-4">
                <div className="w-full md:w-96 p-4 border border-gray-300 rounded-md">
                    <h2 className="text-2xl md:text-3xl font-bold text-center">Sign Up</h2>
                    <p className="text-base text-center text-gray-600 mb-4">
                        Sign up manually or with Google.
                    </p>

                    {/* Google Sign-Up */}
                    <GoogleLogin onSuccess={handleGoogleSuccess} onError={handleGoogleFailure} />

                    {/* Manual Sign-Up */}
                    <form onSubmit={handleManualSignup} className="mt-6">
                        <label className="block text-gray-700 font-semibold mb-2">
                            Email:
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                required
                                className="w-full p-2 border border-gray-300 rounded-md mb-4"
                            />
                        </label>
                        <label className="block text-gray-700 font-semibold mb-2">
                            Password:
                            <input
                                type="password"
                                name="password"
                                value={formData.password}
                                onChange={handleInputChange}
                                required
                                className="w-full p-2 border border-gray-300 rounded-md mb-4"
                            />
                        </label>
                        <label className="block text-gray-700 font-semibold mb-2">
                            Confirm Password:
                            <input
                                type="password"
                                name="confirmPassword"
                                value={formData.confirmPassword}
                                onChange={handleInputChange}
                                required
                                className="w-full p-2 border border-gray-300 rounded-md mb-4"
                            />
                        </label>
                        <button
                            type="submit"
                            className="bg-blue-500 text-white px-4 py-2 rounded-md w-full hover:bg-blue-600 transition-colors duration-200 ease-in-out"
                        >
                            Sign Up
                        </button>
                    </form>

                    {message && <p className="mt-4 text-center text-red-600">{message}</p>}

                    {userInfo && (
                        <div className="mt-4 text-center" key="user-info">
                            <p className="text-lg font-semibold">Logged in as:</p>
                            <p className="text-sm">Name: {userInfo.name}</p>
                            <p className="text-sm">Email: {userInfo.email}</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Signup;
