import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NavigationBar from "./NavigationBar";
import Footer from "./Footer";
import axios from 'axios'

const SelectPrinterShop = () => {
    const location = useLocation();
    const navigate = useNavigate();

    

    // Destructure `selectedShop` and set default values
    const { selectedShop , files , fileOptions , email } = location.state || {};

    const [printerShops, setPrinterShops] = useState([]);
    const [currentShop, setCurrentShop] = useState(selectedShop || null);

    

    
    const [map, setMap] = useState(null);

    
    

    useEffect(() => {
        // If shop is already selected, skip this page
        if (selectedShop) {
            navigate("/success", {
                state: {
                    printerShop: selectedShop,
                    fileDetails: files.map((file, index) => ({
                        ...fileOptions[index],
                        filename: file.name,
                        totalRate: calculateFileRate(fileOptions[index])
                    })),
                    files,
                    email
                },
            });
        }
    }, []);

    // Fetch printer shops from the backend
    useEffect(() => {
        const fetchPrinterShops = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await axios.get(`${apiUrl}/printer-shops`);
                setPrinterShops(response.data);
            } catch (error) {
                console.error("Error fetching printer shops:", error);
                alert("Failed to load printer shops. Please try again.");
            }
        };

        fetchPrinterShops();
    }, []);

    // Calculate total cost based on the shop rates
    const calculateTotalCost = (shop) => {
        if (!files || !fileOptions) return 0;

        return files.reduce((total, _, index) => {
            const options = fileOptions[index];
            const baseRate =
                options.colorOption === "black and white" ? shop.rates.blackWhite : shop.rates.color;
            const printTypeRate = shop.rates[options.printType.toLowerCase()];
            const paperSizeRate = shop.rates[options.paperSize];

            return total + baseRate + printTypeRate + paperSizeRate;
        }, 0);
    };

    // Initialize the map and add markers
    useEffect(() => {
        const initializeMap = () => {
            const mapInstance = new window.google.maps.Map(document.getElementById("map"), {
                center: { lat: 12.971598, lng: 77.594566 },
                zoom: 14,
            });

            setMap(mapInstance);

            printerShops.forEach((shop) => {
                const marker = new window.google.maps.Marker({
                    position: shop.coordinates,
                    map: mapInstance,
                    title: shop.name,
                });

                marker.addListener("click", () => setCurrentShop(shop));
            });
        };

        if (printerShops.length > 0) {
            initializeMap();
        }
    }, [printerShops]);

    // Calculate the rate for a single file
    const calculateFileRate = (options) => {
        if (!currentShop) return 0;

        const baseRate =
            options.colorOption === "black and white"
                ? currentShop.rates.blackWhite
                : currentShop.rates.color;

        const printTypeRate = currentShop.rates[options.printType.toLowerCase()];
        const paperSizeRate = currentShop.rates[options.paperSize];

        return baseRate + printTypeRate + paperSizeRate;
    };

    const handleSelectShop = (shop) => {
        setCurrentShop(shop);
    };

    const handleBack = () => {
        navigate("/review-print", {
            state: { files, fileOptions, email },
        });
    };

    const handleSendCode = () => {
        if (!currentShop) {
            alert("Please select a printer shop!");
            return;
        }

        //alert(`Job sent to ${currentShop.name} successfully!`);
        navigate("/success", {
            state: {
                printerShop: currentShop,
                fileDetails: files.map((file, index) => ({
                    filename: file.name || file.filename || "Unknown File",
                    originalName: file.originalName,
                    colorOption: fileOptions[index].colorOption,
                    printType: fileOptions[index].printType,
                    paperSize: fileOptions[index].paperSize,
                    pagesPerSheet: fileOptions[index].pagesPerSheet || 1,
                    margins: fileOptions[index].margins || "Default",
                    layout: fileOptions[index].layout || "Portrait",
                    totalRate: calculateFileRate(fileOptions[index]),
                })),
                email,
                files,
            },
        });
    };

    return (
        <div>

            <NavigationBar />


            <div>
                {printerShops.map((shop) => (
                    <div key={shop.id} onClick={() => handleSelectShop(shop)}>
                        <h2>{shop.name}</h2>
                    </div>
                ))}
            </div>
            <div className="flex items-center justify-center space-x-4 mt-6 mx-6">
                {/* Progress Steps */}
                <div className="flex flex-col items-center">
                    <div className="w-8 h-8 rounded-full flex items-center justify-center bg-green-600 text-white font-bold">
                        1
                    </div>
                    <span className="text-sm mt-2 text-center text-green-600">Document Selection</span>
                </div>
                <div className="w-16 h-1 bg-gray-300"></div>
                <div className="flex flex-col items-center">
                    <div className="w-8 h-8 rounded-full flex items-center justify-center bg-green-600 text-white font-bold">
                        2
                    </div>
                    <span className="text-sm mt-2 text-center text-green-600">Print Selection</span>
                </div>
                <div className="w-16 h-1 bg-gray-300"></div>
                <div className="flex flex-col items-center">
                    <div className="w-8 h-8 rounded-full flex items-center justify-center bg-[#5360FD] text-white font-bold">
                        3
                    </div>
                    <span className="text-sm mt-2 text-center text-[#5360FD]">Shop Selection</span>
                </div>
            </div>
            <div className="flex flex-col items-center justify-start min-h-screen m-8">
                <div className="w-full mx-8 p-4 md:px-8 md:py-4 border border-gray-300 rounded-md">
                    <h1 className="text-3xl font-semibold text-left mb-0.5">Select a Printer Shop</h1>
                    <p className="text-sm text-gray-500 text-left mb-4">
                        Please select a printer shop from the list below or on the map.
                    </p>
                    <div id="map" className="w-full h-64 mb-4 rounded-lg"></div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        {printerShops.map((shop, index) => (
                            <div
                                key={index}
                                className={`p-4 border-2 rounded-md cursor-pointer transition-all duration-200 ${currentShop?.name === shop.name
                                    ? "bg-blue-100 border-blue-600 shadow-xl scale-105"
                                    : "bg-white border-gray-300"
                                    }`}
                                onClick={() => setCurrentShop(shop)}
                            >
                                <p className="font-bold text-lg">{shop.name}</p>
                                <p className="text-sm text-gray-500">Distance: {shop.distance} km</p>
                                <p className="text-sm text-gray-500">Queue Size: {shop.queue}</p>
                                <p className="text-sm text-gray-500">
                                    Net Cost: ₹{calculateTotalCost(shop).toFixed(2)}
                                </p>
                            </div>
                        ))}
                    </div>
                    <button
                        onClick={handleSendCode}
                        className="bg-[#5360FD] text-white py-2 px-4 rounded w-full mt-4 hover:bg-[#3B49DF]"
                    >
                        Select Shop
                    </button>
                    <button
                        onClick={handleBack}
                        className="bg-black text-white py-2 px-4 rounded mt-4 hover:bg-gray-600"
                    >
                        Back
                    </button>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default SelectPrinterShop;
