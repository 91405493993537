import React from 'react';
import ReactDOM from "react-dom/client"; // Use createRoot from React 18
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Login from './components/Login';
import SignUp from './components/SignUp';
import ReviewAndPrint from './components/ReviewAndPrint';
import SelectPrinterShop from './components/SelectPrinterShop';
import Success from './components/Success';
import History from './components/History';
import About from './components/about';
import Contact from './components/contact';
import Privacy from './components/privacy';
import HowItWorks from './components/howitworks';
import Printpartner from './components/printpartner';
import { GoogleOAuthProvider } from "@react-oauth/google";

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID

const App = () => {
    return (
        <GoogleOAuthProvider clientId={clientId}>
            <Router>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/review-print" element={<ReviewAndPrint />} />                    
                    <Route path="/select-printer-shop" element={<SelectPrinterShop />} />
                    <Route path="/success" element={<Success />} />
                    <Route path="/history" element={<History />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/privacy" element={<Privacy />} />
                    <Route path="/howitworks" element={<HowItWorks />} />
                    <Route path="/printpartner" element={<Printpartner />} />
                </Routes>
            </Router>
        </GoogleOAuthProvider>
    );
};

// React 18 way to render the root
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

export default App;
