import React from "react";
import NavigationBar from "./NavigationBar";
import Footer from "./Footer";

const about = () => {
  return (
    <>
      <NavigationBar />
      <section className="py-12 px-6 md:px-16 lg:px-24 min-h-screen">
        <div className="container mx-auto flex flex-col lg:flex-row items-center">
          {/* Text Content */}
          <div className="lg:w-1/2 mb-8 lg:mb-0">
            <h2 className="text-6xl font-bold mb-4 text-black">About us</h2>
            <p className="text-gray-500 text-lg mb-6 font-medium">
              At <span className="font-bold">MyPrintCorner</span>, we believe
              printing should be as easy as it is essential. Born from the
              everyday struggle of long queues, countless negotiations with the
              local “printer bhaiya,” and messy processes, we created a solution
              that puts your convenience first.
            </p>
            <p className="text-gray-500 text-lg mb-6 font-medium">
              Simply upload your files, customize your prints, and choose a
              trusted printer nearby. We keep your documents secure and
              automatically delete them after printing—no fuss, no worries.
              We’re not just a service; we’re your partner in saving time and
              cutting out the stress. Whether it’s a last-minute presentation or
              a creative project, we’re here to make printing effortless—so you
              can focus on what truly matters.
            </p>
            <p className="text-gray-500 text-lg font-medium">
              Join the <span className="font-bold">MyPrintCorner</span> family,
              and let’s make printing simple, secure, and stress-free—together.
            </p>
          </div>

          {/* Illustration */}
          <div className="lg:w-1/2 flex justify-center">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRGh5WFH8TOIfRKxUrIgJZoDCs1yvQ4hIcppw&s"
              alt="Team illustration"
              className="w-full max-w-sm lg:max-w-md"
            />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
export default about;
