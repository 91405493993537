import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { CiCirclePlus } from "react-icons/ci";
import NavigationBar from "./NavigationBar";
import { FileUploader } from "react-drag-drop-files";
import { IoIosInformationCircle } from "react-icons/io";
import {
    TbCircleNumber1Filled,
    TbCircleNumber2Filled,
    TbCircleNumber3Filled,
} from "react-icons/tb";
import { FaExclamationTriangle } from "react-icons/fa";
import { FaTrash, FaFile } from "react-icons/fa";
import Footer from "./Footer";

const fileTypes = [
    "PDF",
    "DOCX",
    "DOC",
    "TXT",
    "JPEG",
    "JPG",
    "PNG",
    "PPT",
    "PPTX",
];

const Home = () => {
    const [files, setFiles] = useState([]);
    const [uploadMessage, setUploadMessage] = useState("");
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userEmail, setUserEmail] = useState("");
    const [token, setToken] = useState("");

    const loadingModal = document.getElementById("uploadingContentModal");
    const progressBar = document.getElementById("progressBar");
    const [searchParams] = useSearchParams(); // Retrieve shopId from URL
    const [selectedShop, setSelectedShop] = useState(null); // Store shop details
    const navigate = useNavigate();// Store shop details



    // const cancelUploadsBTN = document.getElementById("cancelUploadsBTN");

    const randomPercentageTo100 = (currentPercentage = 0, elapsedTime = 0) => {
        const totalDuration = 1950; // Total duration in milliseconds
        const remainingTime = totalDuration - elapsedTime;

        // Calculate the maximum increment to ensure we reach 100 within the remaining time.
        const maxIncrement = Math.ceil(
            (100 - currentPercentage) * (remainingTime / totalDuration)
        );
        const nextPercentage = Math.min(
            currentPercentage + Math.floor(Math.random() * maxIncrement) + 1,
            100
        );

        // Update the progress bar width and text.
        progressBar.style.width = `${nextPercentage}%`;
        progressBar.textContent = `${nextPercentage}%`;

        if (nextPercentage < 100) {
            // Calculate a delay that ensures we hit 100 within the remaining time.
            const delay = Math.min(remainingTime, Math.random() * 300 + 150); // Random delay between 150ms and 450ms
            setTimeout(
                () => randomPercentageTo100(nextPercentage, elapsedTime + delay),
                delay
            );
        }
    };

    useEffect(() => {

        sessionStorage.removeItem('generatedCode')

        const fetchShopDetails = async (printerShopId) => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL
                const response = await axios.get(`${apiUrl}/printer-shops/${printerShopId}`);
                setSelectedShop(response.data); // Store shop details
            } catch (error) {
                console.error("Error fetching shop details:", error);
                alert("Invalid shop ID.");
            }
        };

        const email = sessionStorage.getItem("userEmail");
        const userToken = sessionStorage.getItem("token");
        if (email && userToken) {
            setIsLoggedIn(true);
            setUserEmail(email);
            setToken(userToken);
        }
        const printerShopId = searchParams.get('ref')
        if (printerShopId) {
            fetchShopDetails(printerShopId)
        }
        //loadingModal.current = document.getElementById("uploadingContentModal");
    }, []);

    const handleDelete = (fileToDelete) => {
        setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToDelete));
    };

    const handleFileChange = async (fileList) => {
        const selectedFiles = Array.from(fileList);
        if (selectedFiles.length === 0) {
            setUploadMessage("Please select at least one file before uploading.");
            return;
        }

        const formData = new FormData();
        selectedFiles.forEach((file) => {
            formData.append("files", file);
        });

        const emailToUse = isLoggedIn ? userEmail : `guest_${Date.now()}`;
        formData.append("emailToUse", emailToUse);
        setUploadMessage("Uploading...");

        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.post(`${apiUrl}/upload`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    ...(token && { Authorization: `Bearer ${token}` }),
                },
            });

            // Show loading modal
            if (loadingModal.current) {
                loadingModal.current.classList.remove("hidden");
                loadingModal.current.classList.add("flex");
            }

            setTimeout(() => {
                if (loadingModal.current) {
                    loadingModal.current.classList.add("hidden");
                    loadingModal.current.classList.remove("flex");
                }

                setUploadMessage(response.data.message || "Files uploaded successfully!");

                // Pass shopId, selectedShop, and files to ReviewAndPrint
                navigate("/review-print", {
                    state: {
                        email: emailToUse,
                        files: response.data.files,
                        selectedShop, // Pass selectedShop here
                    },
                });

                setFiles([]); // Reset file list after upload
            }, 2000);
        } catch (error) {
            setUploadMessage("Error uploading files. Please try again later.");
            console.error("Error uploading files:", error);
        }
    };

    return (
        <div>
            <div
                id="uploadingContentModal"
                className="absolute h-screen w-full z-50 bg-black bg-opacity-25 hidden items-center justify-center overflow-hidden"
            >
                <div className="bg-white px-16 py-8 text-xl font-semibold shadow-md shadow-gray-600">
                    <div className="text-center mb-1">Uploading Files...</div>
                    <p className="text-sm text-gray-500 font-medium">
                        Please wait while we upload your files.
                    </p>
                    <div className="mt-4 w-full bg-gray-200 rounded-full h-4">
                        <div
                            id="progressBar"
                            className="h-4 bg-[#5360FD] text-white text-xs font-bold flex items-center justify-center rounded-full"
                            style={{ width: "0%" }}
                        >
                            0%
                        </div>
                    </div>
                </div>
            </div>

            <NavigationBar />
            <div
                className="
                absolute hidden md:block
                right-0 bottom-0 -z-50
            "
            >
                <svg
                    width="500"
                    height="600"
                    viewBox="0 0 549 802"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M566.756 821.293C550.924 762.376 516.719 628.882 491.833 487.629C472.344 376.958 462.302 283.266 461.994 209.181C461.855 175.175 463.761 145.337 467.705 119.75C468.707 113.231 469.838 106.99 471.109 101.026C472.428 94.7843 473.907 88.8404 475.524 83.2042C477.211 77.3001 479.057 71.7432 481.056 66.5188C483.165 61.0165 485.447 55.8963 487.903 51.1481C490.503 46.1073 493.306 41.4881 496.298 37.3056C499.096 33.3761 502.063 29.8237 505.209 26.6484C505.65 26.2068 506.087 25.7702 506.534 25.3336L529.312 3.3592C528.87 3.80077 528.424 4.2374 527.987 4.67401C524.866 7.83447 521.919 11.3472 519.141 15.2469L519.136 15.2519C516.134 19.4443 513.326 24.0585 510.726 29.0993C508.265 33.8425 505.978 38.9677 503.874 44.465C501.87 49.6845 500.019 55.2463 498.337 61.1356C496.71 66.7718 495.236 72.7107 493.907 78.9622C492.631 84.9507 491.49 91.222 490.488 97.7711C489.516 104.052 488.672 110.592 487.953 117.384C485.735 138.257 484.668 161.541 484.777 187.202C485.075 261.296 495.117 354.989 514.621 465.655C539.408 606.402 573.464 739.439 589.365 798.684L566.756 821.293Z"
                        fill="#737373"
                        fillOpacity="0.05"
                    />
                    <path
                        d="M521.309 866.74C505.625 808.477 471.099 674.076 446.043 531.8C426.544 421.13 416.507 327.442 416.2 253.348C415.981 201.56 420.526 159.437 429.75 127.291C431.432 121.422 433.272 115.89 435.262 110.695C437.375 105.198 439.653 100.073 442.109 95.3147C444.708 90.2739 447.507 85.6597 450.508 81.4771C453.302 77.5526 456.269 74.0002 459.409 70.8199C459.851 70.3784 460.292 69.9467 460.739 69.5101C464.47 65.9081 468.692 62.5392 473.401 59.3887C470.409 63.5712 467.611 68.1854 465.006 73.2312C462.555 77.9744 460.273 83.1045 458.169 88.6019C456.164 93.8213 454.314 99.393 452.622 105.292C451.014 110.899 449.551 116.808 448.231 123.02C441.98 152.411 438.923 188.551 439.102 231.259C439.405 305.359 449.442 399.046 468.935 509.722C493.911 651.481 528.275 785.421 544.032 844.016L521.309 866.74Z"
                        fill="#737373"
                        fillOpacity="0.05"
                    />
                    <path
                        d="M475.856 912.192C460.342 854.634 425.497 719.31 400.244 575.983C380.75 465.307 370.708 371.614 370.405 297.525C370.113 226.263 378.825 173.294 396.344 139.432L396.349 139.427C398.949 134.416 401.727 129.832 404.704 125.664C407.507 121.73 410.474 118.177 413.62 115.002C414.051 114.561 414.493 114.119 414.944 113.687L437.172 92.2537C436.954 92.472 436.735 92.6903 436.517 92.9185C433.376 96.0889 430.404 99.6364 427.616 103.556L427.611 103.561C424.614 107.748 421.811 112.377 419.201 117.418C416.76 122.152 414.488 127.242 412.384 132.71C399.45 166.413 393.044 214.157 393.298 275.436C393.6 349.536 403.637 443.223 423.136 553.894C448.3 696.685 482.981 831.548 498.585 889.463L475.856 912.192Z"
                        fill="#737373"
                        fillOpacity="0.05"
                    />
                    <path
                        d="M430.524 957.525C415.188 900.721 380.001 764.439 354.569 620.04C335.07 509.37 325.033 415.682 324.73 341.582C324.343 249.855 338.89 188.442 367.95 159.055C368.377 158.618 368.828 158.177 369.275 157.75C372.981 154.173 377.178 150.819 381.862 147.673C358.721 180.022 347.176 237.739 347.518 319.608C347.821 393.708 357.858 487.395 377.347 598.066C402.69 741.929 437.703 877.739 453.133 934.915L430.524 957.525Z"
                        fill="#737373"
                        fillOpacity="0.05"
                    />
                </svg>
            </div>
            <div
                className="
                absolute hidden md:block
                left-0 bottom-0 -z-50
            "
            >
                <svg
                    width="582"
                    height="667"
                    viewBox="0 0 582 667"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M-167.259 97.4778C-89.2853 124.339 -12.9907 158.959 42.6631 192.767C76.491 213.321 103.138 233.67 121.852 253.243C127.033 258.659 131.602 264.015 135.558 269.302C137.81 272.315 139.863 275.305 141.713 278.274C139.522 280.7 137.132 283.105 134.541 285.479C132.282 282.46 129.821 279.415 127.159 276.356C125.162 274.053 123.054 271.742 120.835 269.421C102.121 249.848 75.4745 229.499 41.6465 208.944C-14.0072 175.136 -90.2977 140.517 -168.276 113.655L-167.259 97.4778Z"
                        fill="#737373"
                        fillOpacity="0.05"
                    />
                    <path
                        d="M-165.219 64.9615C-87.2453 91.8225 -10.9506 126.443 44.7032 160.25C78.5323 180.801 105.179 201.15 123.893 220.723C134.991 232.333 143.297 243.676 148.753 254.661C150.201 257.571 151.446 260.455 152.49 263.312C151.099 265.857 149.505 268.38 147.71 270.872C146.263 267.957 144.61 265.019 142.762 262.06C137.649 253.863 131.015 245.494 122.873 236.981C104.159 217.408 77.5122 197.059 43.6831 176.508C-11.9707 142.701 -88.2653 108.081 -166.239 81.2196L-165.219 64.9615Z"
                        fill="#737373"
                        fillOpacity="0.05"
                    />
                    <path
                        d="M-163.182 32.4398C-85.2041 59.302 -8.90944 93.9221 46.7443 127.73C80.5734 148.28 107.22 168.629 125.934 188.202C142.94 205.991 153.383 223.152 157.092 239.368C157.732 242.171 158.171 244.944 158.408 247.689C157.832 250.348 157.045 252.978 156.061 255.583C155.421 252.779 154.574 249.953 153.53 247.096C148.541 233.436 138.97 219.163 124.914 204.461C106.2 184.887 79.5533 164.538 45.7242 143.988C-9.9295 110.18 -86.2242 75.5602 -164.202 48.698L-163.182 32.4398Z"
                        fill="#737373"
                        fillOpacity="0.05"
                    />
                    <path
                        d="M-161.144 -5.71465e-05C-83.1661 26.8621 -6.87569 61.4811 48.7781 95.2886C82.6072 115.839 109.254 136.188 127.968 155.761C148.657 177.396 159.627 198.103 160.585 217.309C160.686 219.373 160.674 221.419 160.548 223.449L159.532 239.627C159.659 237.592 159.671 235.546 159.568 233.486C158.61 214.28 147.635 193.576 126.952 171.939C108.238 152.365 81.5906 132.017 47.7615 111.466C-7.89228 77.6585 -84.187 43.0383 -162.161 16.1773L-161.144 -5.71465e-05Z"
                        fill="#737373"
                        fillOpacity="0.05"
                    />
                    <path
                        d="M153.314 328.954C153.374 329.629 153.424 330.293 153.457 330.965C153.559 333.029 153.547 335.076 153.42 337.11C153.363 338.017 153.281 338.918 153.179 339.822C149.994 367.759 124.8 392.912 78.0607 414.843C71.49 417.923 64.4997 420.941 57.0813 423.894C50.0429 421.221 43.627 418.584 37.823 415.97C42.7147 413.812 48.0048 411.644 53.6991 409.442C55.1795 408.871 56.6471 408.296 58.0978 407.716C114.937 385.088 146.87 358.654 153.314 328.954Z"
                        fill="#737373"
                        fillOpacity="0.05"
                    />
                    <path
                        d="M155.347 296.481C155.409 297.166 155.454 297.846 155.49 298.529C155.596 300.593 155.579 302.639 155.454 304.669C155.397 305.576 155.318 306.482 155.216 307.387C155.008 309.181 154.716 310.967 154.328 312.735L154.327 312.74C153.751 315.381 152.978 317.996 151.998 320.584C141.697 347.832 109.115 372.178 54.7192 393.184C48.9895 395.399 43.6671 397.586 38.7509 399.751C32.4645 402.516 26.8315 405.252 21.8207 407.973C17.0573 405.292 12.939 402.63 9.45336 399.964C13.28 397.235 17.7085 394.499 22.7655 391.758C31.7199 386.89 42.6496 381.977 55.7393 376.926C104.938 357.927 136.293 336.193 149.457 312.053C150.836 309.518 152.018 306.957 153.002 304.371C153.986 301.766 154.772 299.136 155.347 296.481Z"
                        fill="#737373"
                        fillOpacity="0.05"
                    />
                    <path
                        d="M158.515 255.89L157.495 272.148C157.438 273.056 157.359 273.962 157.253 274.865C157.05 276.656 156.755 278.436 156.368 280.205C155.792 282.864 155.008 285.5 154.019 288.107C153.031 290.706 151.845 293.284 150.45 295.828C149.071 298.363 147.484 300.874 145.697 303.355C130.497 324.49 100.764 343.668 56.7605 360.664C36.2281 368.589 21.0117 376.166 10.4174 383.745C9.27579 384.562 8.18852 385.38 7.15555 386.198C4.74537 388.103 2.62903 390.013 0.815009 391.931C-1.44036 389.254 -3.07465 386.566 -4.09945 383.86C-2.73415 381.139 -0.765025 378.422 1.79176 375.716C3.61881 373.783 5.74932 371.854 8.17676 369.936C18.8148 361.532 35.0389 353.18 57.7805 344.405C96.591 329.419 124.299 312.73 140.725 294.495C142.916 292.069 144.907 289.611 146.694 287.131C148.49 284.635 150.083 282.117 151.471 279.566C152.862 277.02 154.052 274.444 155.04 271.845C156.025 269.236 156.812 266.606 157.389 263.943C157.772 262.173 158.067 260.393 158.274 258.603C158.379 257.704 158.458 256.798 158.515 255.89Z"
                        fill="#737373"
                        fillOpacity="0.05"
                    />
                    <path
                        d="M160.551 223.454L159.535 239.632C159.478 240.539 159.399 241.445 159.294 242.344C159.086 244.139 158.795 245.92 158.408 247.689C157.832 250.348 157.045 252.978 156.061 255.582C155.074 258.181 153.882 260.762 152.49 263.312C151.099 265.857 149.505 268.38 147.71 270.872C145.914 273.368 143.917 275.832 141.713 278.274C139.522 280.7 137.132 283.105 134.541 285.479C117.699 300.907 92.4153 315.163 58.8017 328.143C36.059 336.921 19.8348 345.274 9.19679 353.678C0.363434 360.656 -4.49705 367.735 -5.30162 374.792C-5.33989 375.105 -5.36544 375.421 -5.38561 375.733L-4.36903 359.556C-4.3531 359.242 -4.32332 358.928 -4.28929 358.614C-3.48471 351.557 1.37577 344.478 10.2091 337.499C20.8514 329.096 37.0756 320.744 59.814 311.964C88.2415 300.986 110.714 289.094 127.159 276.356C130.159 274.032 132.956 271.682 135.558 269.302C138.16 266.917 140.562 264.502 142.762 262.06C144.961 259.622 146.959 257.152 148.753 254.661C150.549 252.165 152.138 249.645 153.531 247.095C154.919 244.545 156.108 241.972 157.092 239.368C158.077 236.759 158.86 234.128 159.432 231.468C159.816 229.712 160.107 227.944 160.311 226.167C160.412 225.263 160.495 224.362 160.551 223.454Z"
                        fill="#737373"
                        fillOpacity="0.05"
                    />
                    <path
                        d="M-10.4882 457.033C-10.5494 458.008 -10.5338 458.985 -10.4363 459.961C-10.2673 461.688 -9.86378 463.405 -9.21733 465.114C-4.00544 478.929 16.9458 492.256 55.8045 506.623C93.5698 520.592 143.301 533.872 195.952 547.93C254.473 563.556 314.991 579.717 362.403 597.853C386.241 606.972 405.053 615.967 418.976 624.939C423.151 627.63 426.889 630.318 430.19 633.012C426.515 635.804 422.44 638.508 417.964 641.117C413.67 638.348 408.907 635.581 403.678 632.803C391.958 626.585 377.878 620.34 361.391 614.031C313.974 595.894 253.46 579.734 194.935 564.107C142.285 550.049 92.5533 536.769 54.7911 522.806C11.1198 506.654 -9.92862 491.823 -11.4529 476.138C-11.5462 475.163 -11.5617 474.186 -11.5006 473.212L-11.4692 472.738L-10.4882 457.033Z"
                        fill="#737373"
                        fillOpacity="0.05"
                    />
                    <path
                        d="M-8.44708 424.513C-8.50934 425.491 -8.49267 426.464 -8.39513 427.44C-8.22526 429.181 -7.8167 430.913 -7.1598 432.635C-6.135 435.341 -4.50496 438.028 -2.25072 440.709C-0.0111827 443.377 2.84829 446.039 6.33816 448.706C17.2147 457.02 34.2175 465.371 57.8446 474.107C95.611 488.072 145.343 501.352 197.993 515.409C256.513 531.04 317.032 547.197 364.444 565.332C402.502 579.889 427.735 594.132 440.745 608.463C443.106 611.068 445.063 613.67 446.622 616.283C444.885 618.831 442.878 621.316 440.603 623.734C440.304 624.054 440.001 624.368 439.689 624.684C437.296 622.048 434.487 619.418 431.26 616.789C416.956 605.116 394.452 593.463 363.423 581.595C316.012 563.455 255.493 547.298 196.973 531.667C144.321 517.614 94.5909 504.33 56.8245 490.365C25.58 478.811 5.91356 467.933 -3.30214 456.932C-5.53744 454.264 -7.16127 451.588 -8.17986 448.893C-8.83676 447.171 -9.24535 445.439 -9.41521 443.698C-9.51389 442.726 -9.52939 441.75 -9.46827 440.775L-8.44708 424.513Z"
                        fill="#737373"
                        fillOpacity="0.05"
                    />
                    <path
                        d="M-6.40569 391.992C-6.46795 392.971 -6.45129 393.943 -6.35799 394.918C-6.18926 396.664 -5.7807 398.396 -5.11956 400.118C-4.09476 402.825 -2.46046 405.513 -0.206232 408.194C2.04262 410.878 4.91793 413.549 8.43211 416.226C11.9189 418.888 16.036 421.555 20.7952 424.235C25.4709 426.869 30.7706 429.516 36.7088 432.189C43.575 435.284 51.2916 438.41 59.886 441.586C97.6524 455.551 147.38 468.83 200.03 482.887C258.554 498.519 319.074 514.676 366.486 532.812C418.602 552.749 446.669 572.087 452.23 591.895C452.305 592.156 452.372 592.415 452.439 592.674C453.092 595.294 453.56 597.874 453.825 600.413C453.186 603.033 452.312 605.599 451.195 608.108C450.438 605.419 449.261 602.736 447.667 600.068C437.533 583.048 410.466 566.282 365.465 549.074C318.054 530.934 257.534 514.777 199.014 499.147C146.358 485.092 96.6323 471.809 58.8659 457.844C42.8034 451.904 29.8018 446.143 19.7045 440.451C14.9727 437.788 10.8788 435.136 7.41206 432.485C3.89787 429.807 1.02254 427.136 -1.22631 424.452C-3.48168 421.775 -5.11173 419.088 -6.14077 416.381C-6.79654 414.655 -7.20934 412.922 -7.37807 411.177C-7.4725 410.206 -7.48803 409.229 -7.42691 408.254L-6.40569 391.992Z"
                        fill="#737373"
                        fillOpacity="0.05"
                    />
                    <path
                        d="M-4.36909 359.557C-4.43022 360.531 -4.41469 361.508 -4.3214 362.483C-4.1535 364.214 -3.75 365.931 -3.09931 367.642C-2.07875 370.347 -0.452937 373.033 1.79168 375.716C4.03743 378.395 6.90734 381.069 10.4173 383.745C13.8987 386.41 18.0116 389.076 22.7654 391.759C27.4612 394.403 32.784 397.066 38.7508 399.751C44.5779 402.38 51.0224 405.03 58.0978 407.717C59.3518 408.194 60.6281 408.672 61.9226 409.151C67.0876 411.062 72.4713 412.954 78.0606 414.843C113.34 426.759 156.615 438.314 202.067 450.452C260.592 466.079 321.106 482.239 368.522 500.376C421.319 520.573 449.434 540.156 454.472 560.237C455.854 565.749 456.382 571.09 456.057 576.253L455.041 592.43C455.365 587.267 454.837 581.926 453.459 576.416C448.422 556.335 420.306 536.751 367.506 516.554C320.094 498.418 259.575 482.257 201.05 466.629C148.4 452.572 98.6724 439.293 60.906 425.328C59.6115 424.849 58.3352 424.371 57.0812 423.894C50.0428 421.222 43.6269 418.584 37.823 415.97C31.852 413.284 26.5207 410.619 21.8206 407.973C17.0572 405.292 12.9389 402.63 9.45326 399.964C5.93908 397.286 3.06377 394.616 0.814922 391.931C-1.44045 389.254 -3.07474 386.567 -4.09954 383.86C-4.75955 382.133 -5.16811 380.401 -5.33798 378.66C-5.43127 377.685 -5.4468 376.708 -5.38568 375.734L-4.36909 359.557Z"
                        fill="#737373"
                        fillOpacity="0.05"
                    />
                    <path
                        d="M449.943 673.731L448.926 689.908C448.876 690.69 448.807 691.472 448.722 692.249C447.53 702.702 442.772 712.4 434.484 721.211C421.922 734.572 401.109 746.208 372.635 755.801C349.612 763.554 321.616 769.971 289.412 774.873C236.694 782.902 189.701 784.201 185.475 784.3L186.491 768.123C190.714 768.022 237.706 766.723 290.425 758.695C322.629 753.792 350.63 747.373 373.647 739.622C402.126 730.031 422.935 718.393 435.501 705.033C442.285 697.815 446.705 690.004 448.734 681.666C449.187 679.824 449.518 677.962 449.734 676.07C449.824 675.294 449.892 674.513 449.943 673.731Z"
                        fill="#737373"
                        fillOpacity="0.05"
                    />
                    <path
                        d="M451.984 641.21L450.964 657.468C450.912 658.255 450.844 659.036 450.754 659.812C450.54 661.714 450.201 663.591 449.744 665.446C449.109 668.053 448.238 670.61 447.131 673.114C444.712 678.597 441.175 683.822 436.521 688.775C423.956 702.131 403.147 713.768 374.667 723.364C351.65 731.115 323.649 737.534 291.445 742.437C238.726 750.465 191.735 751.76 187.511 751.864L188.533 735.602C192.755 735.501 239.746 734.207 292.466 726.174C324.67 721.272 352.67 714.856 375.687 707.106C404.167 697.51 424.976 685.872 437.541 672.517C439.825 670.083 441.843 667.588 443.589 665.024C445.393 662.38 446.907 659.667 448.132 656.891L448.133 656.887C449.252 654.373 450.127 651.803 450.766 649.184C451.221 647.333 451.561 645.451 451.775 643.55C451.865 642.774 451.932 641.996 451.984 641.21Z"
                        fill="#737373"
                        fillOpacity="0.05"
                    />
                    <path
                        d="M452.803 616.676C453.107 619.48 453.171 622.242 453 624.951C452.953 625.734 452.885 626.516 452.795 627.291C452.581 629.193 452.242 631.07 451.786 632.925C451.147 635.545 450.273 638.111 449.154 640.625C447.924 643.418 446.398 646.145 444.581 648.804C442.84 651.351 440.833 653.836 438.562 656.254C438.276 656.559 437.985 656.863 437.69 657.166C425.045 670.146 404.539 681.465 376.709 690.843C353.691 698.594 325.69 705.013 293.486 709.916C240.767 717.944 193.776 719.239 189.553 719.344L190.574 703.081C194.796 702.981 241.788 701.686 294.507 693.654C326.711 688.751 354.711 682.336 377.729 674.585C399.434 667.27 416.683 658.774 429.222 649.229C432.744 646.55 435.893 643.789 438.668 640.947C438.98 640.63 439.283 640.316 439.582 639.996C441.857 637.579 443.864 635.094 445.602 632.541C447.418 629.887 448.944 627.16 450.174 624.367C451.292 621.857 452.166 619.291 452.803 616.676Z"
                        fill="#737373"
                        fillOpacity="0.05"
                    />
                    <path
                        d="M456.058 576.253L455.041 592.43C454.995 593.213 454.926 593.995 454.837 594.771C454.621 596.677 454.281 598.558 453.825 600.413C453.186 603.033 452.312 605.599 451.195 608.108C449.964 610.901 448.438 613.629 446.622 616.283C444.885 618.831 442.878 621.316 440.603 623.734C440.304 624.054 440.002 624.368 439.69 624.684C436.901 627.542 433.735 630.316 430.19 633.012C426.515 635.804 422.44 638.508 417.964 641.117C407.173 647.412 394.072 653.165 378.75 658.323C355.731 666.078 327.731 672.493 295.527 677.395C242.808 685.428 195.816 686.723 191.594 686.823L192.606 670.645C196.833 670.545 243.825 669.246 296.544 661.218C328.748 656.316 356.744 649.899 379.766 642.146C388.455 639.219 396.431 636.102 403.678 632.803C409.208 630.285 414.311 627.661 418.976 624.939C423.48 622.314 427.576 619.598 431.26 616.789C434.799 614.097 437.966 611.318 440.745 608.463C441.04 608.16 441.329 607.86 441.616 607.555C443.903 605.127 445.918 602.626 447.667 600.068C449.479 597.412 451 594.688 452.23 591.895C453.339 589.383 454.217 586.819 454.85 584.202C455.304 582.356 455.64 580.491 455.853 578.594C455.939 577.817 456.007 577.035 456.058 576.253Z"
                        fill="#737373"
                        fillOpacity="0.05"
                    />
                </svg>
            </div>
            <div
                className="
                flex flex-col items-start justify-center text-left mx-8 md:mx-24 lg:mx-32 mt-16 mb-8 md:my-16
            "
            >
                <h1
                    className="
                    text-4xl md:text-5xl font-semibold font-firaSans mb-0 md:mb-2
                "
                >
                    Print Document,
                </h1>
                <h2
                    className="
                    text-2xl md:text-6xl font-semibold font-firaSans text-[#5360FD]
                "
                >
                    Smartly and Securely<span className="text-black">.</span>
                </h2>
            </div>
            <div
                className="
                flex flex-col items-center justify-center
            "
            >
                <FileUploader
                    name="filesToUpload"
                    multiple={true}
                    required={true}
                    maxSize={10}
                    hoverTitle="Drop files here..."
                    types={fileTypes}
                    handleChange={handleFileChange}
                    onSizeError={() => {
                        setUploadMessage("File size exceeds the limit of 10 MB!");
                        setTimeout(() => setUploadMessage(""), 5000);
                    }}
                >
                    <div
                        className="
                        bg-[#E1E1E1] w-[20rem] py-14 md:py-20 md:w-[28rem] rounded-md border-dashed border-[1px] border-black items-center
                        hover:bg-zinc-300 transition-colors duration-200 ease-linear mb-2
                    "
                    >
                        <div
                            className="
                            flex items-center justify-center flex-col text-xl
                            font-semibold
                        "
                        >
                            <CiCirclePlus
                                className="
                                text-5xl
                            "
                            />
                            <p>Drag & Drop or Choose Files</p>
                        </div>
                        <div
                            className="
                            flex flex-row items-center justify-center mt-2 gap-x-1 text-base
                        "
                        >
                            <IoIosInformationCircle className="text-2xl" />
                            Max File Size: 10 MB
                        </div>
                    </div>
                </FileUploader>
            </div>

            {/* Display uploaded files below the upload box */}
            <div className="mx-8 md:mx-24 lg:mx-32">
                {files.length > 0 && (
                    <div className="mt-4 bg-gray-50 p-4 rounded-md shadow-sm">
                        <h3 className="text-lg md:text-2xl font-semibold">
                            Uploaded Files:
                        </h3>
                        <p
                            className="
                            text-sm md:text-base font-medium
                            mb-2 text-zinc-500
                        "
                        >
                            File(s) Selected: {files.length}
                        </p>
                        <ul className="space-y-2">
                            {files.map((file, index) => (
                                <li
                                    key={index}
                                    className="flex items-center justify-between bg-white p-2 rounded-md shadow hover:shadow-md transition"
                                >
                                    <span className="text-sm md:text-base font-medium truncate flex items-center">
                                        <FaFile className="text-xl md:text-2xl mr-2" />
                                        {file.name}
                                    </span>
                                    <button
                                        className="text-black hover:text-red-600 bg-red-500 bg-opacity-50 p-2 rounded-md shadow-sm shadow-red-100
                                            transition-colors duration-100 ease-linear
                                        "
                                        onClick={() => handleDelete(file)}
                                    >
                                        <FaTrash />
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>

            {/* Upload message */}
            {uploadMessage && (
                <div
                    className="
                    fixed bottom-0 right-0 mx-8 my-8
                "
                >
                    <div
                        className="bg-red-100 rounded-r-lg w-fit border-2 border-red-500 text-red-700 px-8 py-3 flex justify-start flex-col"
                        role="alert"
                    >
                        <p className="font-bold text-lg items-center flex justify-start gap-x-2">
                            <FaExclamationTriangle className="text-xl md:text-2xl" />
                            Attention!
                        </p>
                        <p className="text-sm">{uploadMessage}</p>
                    </div>
                </div>
            )}

            {/* How it works section */}
            <div
                className="
                flex flex-col items-start justify-center mt-4 mx-8 md:mx-24 lg:mx-32
            "
            >
                <h3
                    className="
                    text-3xl md:text-4xl font-semibold font-firaSans"
                >
                    How it works?
                </h3>
                <div
                    className="
                    flex flex-col md:flex-row items-center justify-between mt-4 gap-y-2 gap-x-0 md:gap-y-0 md:gap-x-20
                "
                >
                    <div
                        className="
                        w-full md:w-1/3
                    "
                    >
                        <h4
                            className="
                            text-xl md:text-2xl
                            flex items-center gap-x-1 md:gap-x-2
                            justify-start font-semibold mb-1
                        "
                        >
                            <TbCircleNumber1Filled className="text-2xl md:text-3xl" />
                            Upload & Customize
                        </h4>
                        <p
                            className="
                            text-sm md:text-lg font-medium
                        "
                        >
                            Upload your files & set preferences like color, size & page count.
                        </p>
                    </div>
                    <div
                        className="
                        w-full md:w-1/3
                    "
                    >
                        <h4
                            className="
                            text-xl md:text-2xl
                            flex items-center gap-x-1 md:gap-x-2
                            justify-start font-semibold mb-1
                        "
                        >
                            <TbCircleNumber2Filled className="text-2xl md:text-3xl" />
                            Pick a Shop
                        </h4>
                        <p
                            className="
                            text-sm md:text-lg font-medium
                        "
                        >
                            Select a nearby print shop and receive a unique code.
                        </p>
                    </div>
                    <div
                        className="
                        w-full md:w-1/3
                    "
                    >
                        <h4
                            className="
                            text-xl md:text-2xl
                            flex items-center gap-x-1 md:gap-x-2
                            justify-start font-semibold mb-1
                        "
                        >
                            <TbCircleNumber3Filled className="text-2xl md:text-3xl" />
                            Print Instantly
                        </h4>
                        <p
                            className="
                            text-sm md:text-lg font-medium
                        "
                        >
                            Share the code at the shop to get your documents printed.
                        </p>
                    </div>
                </div>
            </div>
            <div
                className="flex flex-col items-start justify-center text-left
                bg-white w-full mt-16 md:mt-32 md:pt-8 px-8 md:px-24 lg:px-32"
            >
                <h1 className="text-3xl md:text-4xl font-semibold mb-2 md:mb-4 text-[#5360FD]">
                    Become a Print Partner:
                </h1>
                <h3>
                    <span className="font-semibold text-lg md:text-xl">
                        Join our network of trusted printing partners.
                    </span>
                    <br />
                    <span
                        className="
                        text-base md:text-lg
                        text-gray-400
                        font-medium
                        mt-2
                    "
                    >
                        Expand your reach and grow your business with us!
                    </span>
                </h3>
                <p
                    className="
            text-base md:text-lg
            text-gray-600 mt-4
            font-medium w-full
        "
                >
                    Becoming a partner with My Print Corner connects your print shop to a
                    growing community of customers who need secure, reliable, and
                    affordable paper printing services.
                </p>
                <h3
                    className="
            text-xl md:text-2xl
            font-semibold
            text-gray-800 mt-6
        "
                >
                    Why Parnter With us?
                </h3>
                <p>
                    <ul
                        className="
            text-base md:text-lg
            text-gray-600 mt-2
            font-medium w-full
            list-decimal list-inside
          "
                    >
                        <li>
                            <span
                                className="
                font-bold pr-1
              "
                            >
                                Increase your customer base:
                            </span>
                            Gain access to a steady stream of local and online customers.
                        </li>
                        <li>
                            <span
                                className="
                font-bold pr-1
              "
                            >
                                Boost your Revenue:
                            </span>
                            Receive more print jobs effortlessly.
                        </li>
                        <li>
                            <span
                                className="
                font-bold pr-1
              "
                            >
                                Seamless Integration:
                            </span>
                            Our platform makes onboarding and order management simple.
                        </li>
                        <li>
                            <span
                                className="
                font-bold pr-1
              "
                            >
                                No Upfront Costs:
                            </span>
                            Join our network for free and start earning instantly.
                        </li>
                    </ul>
                </p>
            </div>
            <Footer />
        </div>
    );
};

export default Home;
