import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import NavigationBar from "./NavigationBar";
import Footer from "./Footer";

const History = () => {
    const navigate = useNavigate();
    const [history, setHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const email = sessionStorage.getItem("userEmail");

    useEffect(() => {
        if (!email || email.startsWith("guest_")) {
            console.log("Invalid session. Redirecting to login...");
            setHistory([]);
            navigate("/login");
            return;
        }

        const fetchHistory = async () => {
            try {
                setLoading(true);
                const token = sessionStorage.getItem("token")
                const response = await fetch(`${process.env.REACT_APP_API_URL}/history`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        ...(token && { Authorization: `Bearer ${token}` })
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                setHistory(data);
                setError(null);
            } catch (err) {
                console.error("Error fetching history:", err);
                setError("Failed to load print history. Please try again later.");
            } finally {
                setLoading(false);
            }
        };

        fetchHistory();
    }, [email, navigate]);


    if (loading) {
        return (
            <>
                <NavigationBar />
                <div className="flex items-center justify-center min-h-screen">
                    <div className="text-xl">Loading print history...</div>
                </div>
                <Footer />
            </>
        );
    }



    if (error) {
        return (
            <>
                <NavigationBar />
                <div className="flex flex-col items-center justify-center min-h-screen">
                    <div className="text-xl text-red-600">{error}</div>
                    <button
                        onClick={() => window.location.reload()}
                        className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                    >
                        Retry
                    </button>
                </div>
                <Footer />
            </>
        );
    }

    return (
        <>
            <NavigationBar />
            <div className="container mx-auto px-4 py-8">
                <div className="mb-8">
                    <h1 className="text-xl md:text-3xl font-bold">Print History</h1>
                    <p className="text-gray-600 mt-2">
                        View all your previous print jobs below
                    </p>
                </div>

                <div className="overflow-x-auto shadow-md rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Printer Shop
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Total Cost
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Files
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Date & Time
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Status
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {history.length > 0 ? (
                                history.map((job, index) => (
                                    <tr key={index} className="hover:bg-gray-50">
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm font-medium text-gray-900">
                                                {job.printerShop.name}
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm text-gray-900">
                                                {job.totalAmount.toLocaleString('en-IN', { style: 'currency', currency: "INR" })}
                                            </div>
                                        </td>
                                        <td className="px-6 py-4">
                                            <div className="text-sm text-gray-900">
                                                {job.files.map(file => file.originalName).join(', ')}
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm text-gray-900">
                                                {new Date(job.createdAt).toLocaleString()}
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <span
                                                className={`flex p-2 justify-center w-24 items-center text-sm font-medium rounded-full 
    ${job.status === 'Completed' ? 'bg-green-100 text-green-800' :
                                                        job.status === 'Processing' ? 'bg-yellow-100 text-yellow-800' :
                                                            'bg-red-100 text-red-700'}`}
                                            >
                                                {job.status}
                                            </span>

                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={5} className="px-6 py-4 text-center text-sm text-gray-500">
                                        No print history available.
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default History;